import { template as template_4c09bb251aff41b3a91027a490434b87 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4c09bb251aff41b3a91027a490434b87(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
