import { template as template_44fdd26f86294f66b5dcabbb4469d3e4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_44fdd26f86294f66b5dcabbb4469d3e4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
