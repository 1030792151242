import { template as template_48bc20f2bbf84778807eae581109442f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_48bc20f2bbf84778807eae581109442f(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
